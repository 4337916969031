import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Privacy Policy | Odia News Live Streaming from OTV, News18 Odia,
            Kanak News, Kalinga TV, Nandighosha TV
          </title>
          <meta
            name="description"
            content="Read the Privacy Policy for Odia News Live, where we display live streaming from OTV, News18 Odia, Kanak News, Kalinga TV, and Nandighosha TV. All videos are owned by respective YouTube channels."
          />
          <meta
            name="keywords"
            content="Odia news live privacy policy, OTV live streaming policy, News18 Odia privacy, Kanak News policy, Kalinga TV privacy terms, Nandighosha TV live policy, live Odia news videos, Odia YouTube streaming, Odisha news channels policy"
          />
        </Helmet>
        <Header />

        <div className="privacy-policy">
          <div className="container">
            <button className="back-arrow" onClick={goBack}>
              <i className="fa fa-long-arrow-left"></i>
            </button>
            <h1>Privacy Policy</h1>
          </div>

          <p>
            Welcome to www.odia-news.in. This Privacy Policy outlines how we
            handle any information you may provide while visiting our website.
            At Odia News Live, we respect your privacy and are committed to
            protecting your personal information.
          </p>
          <ol className="bullet-list">
            <li>
              <h2>Content and Ownership</h2>
              <p>
                Our website displays live streaming of the top Odia news
                channels, including OTV, News18 Odia, Kanak News, Kalinga TV,
                and Nandighosha TV. All the content and videos on our website
                are directly linked from YouTube and belong to their respective
                owners. We do not own, host, or claim any rights to the content
                of these videos. We simply provide links to live YouTube streams
                to enhance user experience.
              </p>
              <p>
                By accessing and using this website, you agree to comply with
                the terms of this Privacy Policy.
              </p>
            </li>
            <li>
              <h2>Personal Information</h2>
              <p>
                We do not collect or store any personal information unless you
                voluntarily submit it to us via our contact form or email. Any
                information you provide will only be used to respond to your
                inquiries or improve our services. We will not sell, share, or
                disclose your personal information to third parties without your
                consent.
              </p>
            </li>
            <li>
              <h3>Cookies</h3>
              <p>
                Our website may use cookies to improve user experience by saving
                preferences and tracking visitor trends. You can control how
                cookies are used through your browser settings.
              </p>
            </li>
            <li>
              <h2>Third-Party Links</h2>
              <p>
                Our website contains links to third-party platforms (YouTube)
                for live news streaming. We are not responsible for the content
                or privacy practices of these external websites. You are
                encouraged to review the privacy policies of those platforms
                before engaging with their content.
              </p>
            </li>
            <li>
              <h2>Embedded Content from Other Websites</h2>
              <p>
                The videos displayed on our website are embedded from YouTube.
                These third-party websites may collect data about you, use
                cookies, embed additional third-party tracking, and monitor your
                interaction with the embedded content.
              </p>
            </li>
            <li>
              <h2>Changes to this Privacy Policy</h2>
              <p>
                We reserve the right to update this Privacy Policy at any time.
                Any modifications will be posted on this page, and your
                continued use of our website signifies your acceptance of these
                changes.
              </p>
            </li>
            <li>
              <h2>Contact </h2>
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at contact@odia-news.in.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
